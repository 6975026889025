import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import {
  GET_TERMS_CONDITIONS_LOADING,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_TERMS_CONDITIONS_ERROR
} from '../actions/types';

export function fetchTermsAndConditionsLoading() {
  return {
    type: GET_TERMS_CONDITIONS_LOADING
  };
}
export function fetchTermsAndConditionsSuccess(value) {
  return {
    type: GET_TERMS_CONDITIONS_SUCCESS,
    payload: value
  };
}
export function fetchTermsAndConditionsError(error) {
  return {
    type: GET_TERMS_CONDITIONS_ERROR,
    error
  };
}

export const fetchTermsAndConditions = () => {
  return (dispatch) => {
    dispatch(fetchTermsAndConditionsLoading());
    request
      .get(`${process.env.REACT_APP_API_URL}/v2/terms?include_text=all&apps=all`, {
        headers: getHeaders(),
        useCache: true
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchTermsAndConditionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchTermsAndConditionsError(error));
      });
  };
};
