/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../utils/app.constants';

import {
  StyledCarousel,
  StyledPage,
  StyledWelcomeImage,
  StyledTitle,
  StyledDescription,
  StyledButtonContainer,
  StyledButtonNext,
  StyledButtonBack
} from './InfoCarousel.style';
import { iff } from '../../utils/iff';
import { history, URL_CONSTANTS } from '../../utils/history';

const InfoCarousel = ({
  carouselJson,
  isNewSignedupUser,
  handleCompleteTutorial,
  startPage,
  user,
  createOrg,
  form,
  fetchUserDetails
}) => {
  let carousel = React.createRef();
  const [orgName, setOrgName] = useState('');
  const [orgCreated, setOrgCreated] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  useEffect(() => {
    carousel.goTo(startPage);
  }, [startPage]);

  useEffect(() => {
    if (orgCreated) {
      carousel.next();
    }
  }, [orgCreated]);

  useEffect(() => {
    if (isNewSignedupUser) {
      history.push(URL_CONSTANTS.HALL_OF_ORG());
    }
  }, [carouselJson]);

  const setDisabledBtnVal = () => {
    if (!orgName || Object.values(form.getFieldsError()).some((el) => el !== undefined)) {
      return true;
    }
  };
  const onInputFieldChange = (event) => {
    let targEvent = event.target.value;
    setOrgName(targEvent);
  };
  const onNextClicked = async (i, child) => {
    if (i < carouselJson.children.length - 1) {
      if (child.id === 'Organization' && isNewSignedupUser) {
        setIsNextLoading(true);
        const newOrg = await createOrg({
          name: orgName,
          unit_system: 'IMPERIAL',
          currency: 'USD'
        });
        await fetchUserDetails(false);
        setIsNextLoading(false);
        setOrgCreated(true);
        const newUrl = URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: newOrg.data.id });
        history.push(newUrl);
      } else {
        carousel.next();
      }
    } else {
      handleCompleteTutorial(() => {
        if (!user.auto_created_org_id) {
          history.push(`${history.location.pathname}/create-farm`);
        }
      });
    }
  };

  return (
    <StyledCarousel ref={(node) => (carousel = node)}>
      {carouselJson.children.map((child, i) => (
        <div key={i}>
          <StyledPage>
            <StyledWelcomeImage image={child.image} />
            <StyledTitle>{child.title}</StyledTitle>
            <StyledDescription>{child.description}</StyledDescription>
            <StyledButtonContainer>
              <StyledButtonNext
                disabled={isNewSignedupUser && child.id === 'Organization' && setDisabledBtnVal()}
                loading={isNextLoading}
                onClick={() => onNextClicked(i, child)}>
                {iff(
                  i < carouselJson.children.length - 1,
                  carouselJson.btnText,
                  carouselJson.lastBtnText
                )}
              </StyledButtonNext>
              {iff(
                isNewSignedupUser && child.id === 'Organization',
                <Form name='basic' initialValues={{ remember: true }} style={{ width: '60%' }}>
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          max: AppConstants.VALIDATIONS.NAME_MAX_LENGTH,
                          message: t('Organization name cannot be longer than {{MAX}} characters', {
                            MAX: AppConstants.VALIDATIONS.NAME_MAX_LENGTH
                          })
                        },
                        { required: true, message: t('Please enter Name!') }
                      ]
                    })(
                      <Input
                        placeholder={t('Enter your organization name')}
                        // style={{ width: '60%' }}
                        size='large'
                        onChange={(e) => onInputFieldChange(e)}
                      />
                    )}
                  </Form.Item>
                </Form>
              )}

              {iff(
                i > 0 && i <= carouselJson.children.length - 1,
                <StyledButtonBack
                  type='default'
                  onClick={() => {
                    iff(i > 0 && i <= carouselJson.children.length - 1, () => carousel.prev());
                  }}>
                  {iff(i > 0 && i <= carouselJson.children.length - 1, carouselJson.btnBack)}
                </StyledButtonBack>
              )}
            </StyledButtonContainer>
          </StyledPage>
        </div>
      ))}
    </StyledCarousel>
  );
};
InfoCarousel.propTypes = {
  form: PropTypes.object,
  carouselJson: PropTypes.object,
  startPage: PropTypes.number,
  createOrg: PropTypes.func,
  isNewSignedupUser: PropTypes.bool,
  user: PropTypes.object,
  handleCompleteTutorial: PropTypes.func,
  fetchUserDetails: PropTypes.func
};
InfoCarousel.defaultProps = {
  closeClicked: () => null,
  startPage: 0,
  carouselJson: {}
};

export const InfoCarouselComponent = Form.create({
  name: 'InfoCarousel'
})(InfoCarousel);
