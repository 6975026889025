import { Carousel, Modal, Button } from 'antd';
import styled from 'styled-components';
import WelcomeImage1 from '../../public/welcome-summary-1.svg';
import WelcomeImage2 from '../../public/welcome-summary-2.svg';
import WelcomeImage3 from '../../public/welcome-summary-3.svg';
import WelcomeImage4 from '../../public/welcome-summary-4.svg';
import WelcomeImage5 from '../../public/welcome-summary-5.svg';

const welcomeImages = [WelcomeImage1, WelcomeImage2, WelcomeImage3, WelcomeImage4, WelcomeImage5];
export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    min-height: 445px;
    background: #ffffff;
    overflow: hidden;
  }

  .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: gray;
  }
  .slick-dots li.slick-active button {
    background: #00004b;
    width: 8px;
  }
  .slick-dots-bottom {
    bottom: 40%;
  }
`;
export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .slick-list {
    border-radius: 8px;
  }
`;

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 410px;
`;
export const StyledWelcomeImage = styled.div`
  background-image: url(${(props) => welcomeImages[props.image]});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  width: 100%;
  border-bottom: 2px solid #14803c;
`;
export const StyledImage = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  width: 100%;
  border-bottom: 2px solid #14803c;
`;
export const StyledTitle = styled.h3`
  font-family: Noto Sans, Rubik, sans-serif;
  align-self: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  margin: 10px 32px 20px 32px;
`;
export const StyledDescription = styled.div`
  font-family: Noto Sans, Rubik, sans-serif;
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  margin: 0 32px;
  position: relative;
  top: -20px;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
  flex-direction: row-reverse;
  position: relative;
  top: 5px;
`;
export const StyledButtonNext = styled(Button)`
  cursor: pointer;
  height: 40px;
  font-family: Noto Sans, Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background: #14803c;
  color: #ffffff;
  &:hover {
    background: #14803c;
    color: #ffffff;
    border-color: #14803c;
  }
`;
export const StyledButtonBack = styled(Button)`
  cursor: pointer;
  height: 40px;
  font-family: Noto Sans, Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #14803c;
  background: #ffffff;
  border-color: #14803c;
  &:hover {
    color: #14803c;
    background: #ffffff;
    border-color: #14803c;
  }
`;
