import { normalize } from 'normalizr';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { request } from '../utils/axios';
import { iff } from '../utils/iff';
import * as schema from './schema';
import { getLangHeaders } from '../utils/common-methods';
import {
  FETCH_CROP_DETAILS_ERROR,
  FETCH_CROP_DETAILS_SUCCESS,
  FETCH_CROP_DETAILS_LOADING
} from './types';

export function fetchCropLoading(value) {
  return {
    type: FETCH_CROP_DETAILS_LOADING,
    payload: value
  };
}
export function fetchCropSuccess(crops) {
  return {
    type: FETCH_CROP_DETAILS_SUCCESS,
    payload: normalize(crops, schema.cropsListSchema)
  };
}
export function fetchCropError(error) {
  return {
    type: FETCH_CROP_DETAILS_ERROR,
    error
  };
}

export const fetchCropDetails = (localeForCatalog = '') => {
  return (dispatch) => {
    dispatch(fetchCropLoading());
    const queryParams = `&location=@${localeForCatalog}`;
    return request
      .get(
        `/v2/crops?showDeleted=false${iff(isEmpty(localeForCatalog) === false, queryParams, '')}`,
        {
          headers: getLangHeaders(),
          useCache: false
        }
      )
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        const sortedCrops = sortBy(res.data.content, (crop) => crop.name.toLowerCase()).filter(
          (crop) => crop.name.trim().length > 0
        );
        dispatch(fetchCropSuccess(sortedCrops));
        return res;
      })
      .catch((error) => {
        dispatch(fetchCropError(error));
      });
  };
};
