import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { FarmshotsClient } from '@striderag/cropwise-farmshots-ui-integration';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less';
import { AppLazy } from './app';
import { store } from './reducer/store';
import './i18n';
import { Analytics } from './utils/analytics';
import { ZendeskWidget } from './components/common/ZendeskWidget';
import './components/Maps/mapInit';
import { getAccessToken, cleanNewUserSession } from './utils/common-methods';
import { AppLoader } from './core/AppLoader';

import './index.less';

const sentryEnvs = ['qa', 'staging', 'production'];
if (sentryEnvs.indexOf(process.env.REACT_APP_envName) >= 0) {
  Sentry.init({ dsn: process.env.SENTRY_DSN, environment: process.env.REACT_APP_envName });
}

if (process.env.REACT_APP_SEGMENT_KEY) {
  Analytics.init(process.env.REACT_APP_SEGMENT_KEY);
}

cleanNewUserSession(); // if new login on accounts portal, clear session on main app

const initFarmshots = () => {
  const farmshotsClient = FarmshotsClient.getInstance();

  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }
  farmshotsClient.setBaseURL(`${process.env.REACT_APP_API_URL}/v2`);

  if (tokens && tokens.access_token) {
    farmshotsClient.setToken(tokens.access_token);
  }
};

initFarmshots();

(async function loadAppLoader() {
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    ReactDOM.render(
      <Provider store={store}>
        <AppLazy />
        <ZendeskWidget />
      </Provider>,
      document.getElementById('root')
    );
  }
})();

ReactDOM.render(<div />, document.getElementById('root'));
